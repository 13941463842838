body {
  background-color: #1c1c1c;
  color: #ffffff;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  color: #ec6f00;
  margin-top: 50px;
}
.teams,
.participants {
  display: flex;
  justify-content: space-between;
  width: 600px;
}
.participants-title {
  color: #54b4d3;
  margin-top: 80px;
}
.buttons {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button {
  padding: 8px 12px;
  font-weight: bold;
  font-size: 16px;
  margin: 0 20px;
  cursor: pointer;
}
